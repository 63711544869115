import * as React from 'react';
import {LogEntry, LogPage, LogSearchRequest, Notice, NoticePage, NoticeSearchRequest} from "../../model/Notice";
import {useLoaderData, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useTheme} from "@mui/material/styles";
import {LinearProgress, TablePagination} from "@mui/material";
import {NoticeService} from "../../services/NoticeService";
import {useEffect} from "react";
import UserSelect from "../widgets/UserSelect";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment";
import {toast} from "react-toastify";
import {emptyPage, Page} from "../../model/Commons";
import {User} from "../../model/Users";
import Box from "@mui/material/Box";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {MedrePagination} from "../widgets/MedrePagination";
import {grey} from "@mui/material/colors";
import {MedreLocalizedDate} from "../widgets/MedreLocalizedDate";
import DownloadIcon from "@mui/icons-material/Download";
import {NoticeDiff} from "./NoticeDiff";
import {HttpApiService} from "../../services/HttpApiService";
import {useAuth} from "../../services/auth/AuthProvider";
import {MedreDatePicker} from "../widgets/MedreDatePicker";

export default function LogsPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const auth = useAuth();

    const [page, setPage] = React.useState<Page<LogEntry>>(emptyPage());
    const [loading, setLoading] = React.useState<boolean>(false);

    const [searchRequest, setSearchRequest] = React.useState<LogSearchRequest>({
        pageSize: 20,
        pageNumber: 0
    });

    const fetchData = () => {
        setLoading(true);
        NoticeService.fetchLog(searchRequest).then(page => {
            console.log('page', page);
            setPage(page);
            setLoading(false);
        }, error => {
            console.log('NoticePage', error);
        });
    }

    useEffect(() => {
        fetchData();
    }, [searchRequest]);

    const handleChangeFromDate = (value: Moment | null) => {
        const isoDate = value ? moment(value).format('YYYY-MM-DD') : null;
        setSearchRequest({...searchRequest, createdAtFrom: isoDate, pageNumber: 0})
    };

    const handleChangeToDate = (value: Moment | null) => {
        const isoDate = value ? moment(value).format('YYYY-MM-DD') : null;
        setSearchRequest({...searchRequest, createdAtTo: isoDate, pageNumber: 0})
    };

    const handleChangeUserId = (user: User | null) => {
        const userId = user ? user.userId : null;
        setSearchRequest({...searchRequest, userId: userId, pageNumber: 0})
    };

    return <>
        <TableContainer component={Paper} sx={{marginTop: theme.spacing(2)}}>
            <Box display="flex" flexDirection="row" gap="10px" margin={1} marginBottom={2}>
                <Typography variant="h4" margin={2}>
                    Logiraamat
                </Typography>

                <div>
                    <Button
                        sx={{
                            marginLeft: theme.spacing(1),
                            marginTop: theme.spacing(2)
                        }}
                        color="secondary"
                        variant="contained"
                        startIcon={<DownloadIcon/>}
                        href={HttpApiService.appendParams('/api/logs/zip', searchRequest)}
                        target="_parent"
                    >
                        Laadi alla ZIP failina
                    </Button>
                </div>
            </Box>

            <Box display="flex" flexDirection="row" gap="10px" margin={1}>
                {auth.user?.role == 'administraator' && <UserSelect onSelect={handleChangeUserId}/>}

                <MedreDatePicker onChange={handleChangeFromDate} label="Alates"/>
                <MedreDatePicker onChange={handleChangeToDate} label="Kuni"/>
                <Box flexGrow={1}/>
                <MedrePagination<NoticeSearchRequest>
                    pageRequest={searchRequest}
                    onPageRequestChange={setSearchRequest}
                    total={page.total}
                />
            </Box>
            {loading && <LinearProgress/>}
            {!loading && <Box height={4}/>}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Kuupäev</TableCell>
                        <TableCell>Tegija</TableCell>
                        <TableCell>Tegevus</TableCell>
                        <TableCell>Algseis</TableCell>
                        <TableCell>Lõppseis</TableCell>
                    </TableRow>

                </TableHead>
                <TableBody>
                    {page.content.map((entry, rowNr) => (
                        <TableRow
                            key={entry.id}
                            sx={{
                                '&:last-child td, &:last-child th': {border: 0},
                                '&:nth-child(even)': {background: '#fff'},
                                '&:nth-child(odd)': {background: grey[100]}
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <MedreLocalizedDate isoDate={entry.createdAt}/>
                            </TableCell>
                            <TableCell>{entry.userFullName}</TableCell>
                            <TableCell>{entry.logType}</TableCell>
                            <TableCell><NoticeDiff a={entry.fromEntity} b={entry.toEntity}/></TableCell>
                            <TableCell><NoticeDiff a={entry.toEntity} b={entry.fromEntity}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <MedrePagination<NoticeSearchRequest>
                pageRequest={searchRequest}
                onPageRequestChange={setSearchRequest}
                total={page.total}
            />
        </TableContainer>
    </>
}
