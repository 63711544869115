import * as React from 'react';
import {useLoaderData, useNavigate} from "react-router-dom";
import {UserList} from "../../model/Users";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import 'moment/locale/de';
import {NoticeCreateRequest} from "../../model/Notice";
import {NoticeService} from "../../services/NoticeService";
import {LinearProgress, Paper} from '@mui/material';
import NoticeForm from "./NoticeForm";
import {ToastService} from "../../services/ToastService";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";


export default function AddNoticePage() {
    const theme = useTheme();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSubmit = (request: NoticeCreateRequest) => {
        setLoading(true);
        NoticeService.addNotice(request).then(notice => {
            ToastService.success("Teade on loodud");
            navigate('/notices');
        }, error => { setLoading(false); });
    };


    return (

        <Paper sx={{padding: theme.spacing(4), marginLeft: 'auto', marginRight: 'auto', maxWidth: 900}}>
            <Typography variant="h4" marginBottom={4}>
                Uus Teade
            </Typography>
            {loading && <LinearProgress />}
            <NoticeForm
                loading={loading}
                noticeCreateRequest={{
                    id: null,
                    datetime: '',
                    address: '',
                    personalCode: '',
                    license: false,
                    comment: ''
                }}
                onNoticeRequestSubmit={handleSubmit}
            />
        </Paper>
    )
        ;
}
