export interface Page<T> {
    content: T[],
    total: number,
}

export function emptyPage<T>(): Page<T> {
    return {content: [], total: 0};
}

export interface PageRequest {
    pageNumber: number,
    pageSize: number
}
