import React from "react";
import moment from "moment/moment";


export function MedreLocalizedDate({isoDate}: {isoDate: string|null|undefined}) {
    if (isoDate) {
        const localizedDate = moment.utc(isoDate, 'YYYY-MM-DD[T]HH:mm:ss.SSS')
            .local(false)
            .format("DD.MM.YYYY HH:mm")
        return <>{localizedDate}</>
    } else {
        return <></>
    }
}
