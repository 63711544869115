import {User, UserCreateDTO, UserList} from "../model/Users";
import {HttpApiService} from "./HttpApiService";

const UserService = {
    fetchUsers: (): Promise<UserList> => {
        return HttpApiService.get('/auth/users')
            .then(json => Promise.resolve(json as UserList));
    },
    addUser: (userCreateDTO: UserCreateDTO): Promise<User> => {
        return HttpApiService.post('/auth/users', userCreateDTO)
            .then(json => Promise.resolve(json as User));
    }
};

export {UserService};
