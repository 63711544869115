import React from "react";
import {NoticeCreateRequest} from "../../model/Notice";
import Box from "@mui/material/Box";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import moment, {Moment} from "moment/moment";
import {ToastService} from "../../services/ToastService";

export default function NoticeForm(
    {loading, noticeCreateRequest, onNoticeRequestSubmit}: {
        loading: boolean,
        noticeCreateRequest: NoticeCreateRequest,
        onNoticeRequestSubmit: (ncr: NoticeCreateRequest) => void
    }
) {

    const fromIso = (isoDate: string): moment.Moment => {
        return moment(isoDate, 'YYYY-MM-DD[T]HH:mm:ss.SSSZ')
    }

    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [address, setAddress] = React.useState<string>(noticeCreateRequest.address);
    const [personalCode, setPersonalCode] = React.useState<string>(noticeCreateRequest.personalCode);
    const [license, setLicense] = React.useState<boolean>(noticeCreateRequest.license);
    const [comment, setComment] = React.useState<string>(noticeCreateRequest.comment);
    const [datetime, setDateTime] = React.useState<string>(noticeCreateRequest.datetime);


    const handleChangeDatetime = (value: Moment | null) => {
        const stringValue = value ? moment(value).toISOString() : '';
        setDateTime(stringValue);
        validatePresent('datetime', 'Kuupäev', stringValue);
    };

    const validateLength = (fieldName: string, fieldLabel: string, value: string, expectedLength: number): void => {
        if (value && value.length != expectedLength) {
            errors[fieldName] = `${fieldLabel} pikkus peaks olema ${expectedLength} sümbolit`;
        }
    }

    const validatePresent = (fieldName: string, fieldLabel: string, value: string): void => {
        delete errors[fieldName];
        if (!value || value.trim() == '') {
            delete errors[fieldName];
            errors[fieldName] = fieldLabel + ' on kohustuslik';
        }
    }

    const handleSend = ():void => {
        validatePresent('datetime', 'Kuupäev', datetime);
        validatePresent('address', 'Koht/Aadress', address);
        validatePresent('personalCode', 'Isikukood', personalCode);
        validateLength('personalCode', 'Isikukoodi', personalCode, 11);
        setErrors({...errors});
        if (Object.keys(errors).length > 0) {
            for (let key in errors) {
                ToastService.warning(errors[key])
            }
        } else {
            const newCreateRequest: NoticeCreateRequest = {
                id: noticeCreateRequest.id,
                datetime: datetime,
                address: address,
                personalCode: personalCode,
                license: license,
                comment: comment
            };
            onNoticeRequestSubmit(newCreateRequest);
        }
    }

    return <Box sx={{mt: 1}}>

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
            <DateTimePicker
                autoFocus
                name="date"
                label="Kuupäev"
                value={fromIso(datetime)}
                onChange={handleChangeDatetime}
                slotProps={{
                    textField: {error: !!errors['datetime'], helperText: errors['datetime'], id: "date"},
                }}
            />
        </LocalizationProvider>


        <TextField
            margin="normal"
            required
            fullWidth
            id="address"
            label="Koht/Aadress"
            name="address"
            value={address}
            onChange={(event) => {
                validatePresent('address', 'Koht/Aadress', event.target.value);
                setAddress(event.target.value);
            }}
            error={!!errors['address']}
            helperText={errors['address']}

        />
        <TextField
            margin="normal"
            required
            fullWidth
            id="personalCode"
            label="Isikukood"
            name="personalCode"
            value={personalCode}
            onChange={(event) => {
                validatePresent('personalCode', 'Isikukood', event.target.value);
                setPersonalCode(event.target.value);
            }}
            error={!!errors['personalCode']}
            helperText={errors['personalCode']}
        />
        <FormControlLabel
            control={<Checkbox id="license" onChange={(event) => {
                setLicense(event.target.checked);
            }}
                               name="license"/>}
            label="Litsents"
            checked={license}
        />
        <br/>
        <TextField
            id="comment"
            name="comment"
            label="Kommentar"
            multiline
            fullWidth
            rows={4}
            value={comment}
            onChange={(event) => {
                setComment(event.target.value);
            }}
        />
        <Button
            id="send"
            startIcon={<SendIcon/>}
            fullWidth
            color="secondary"
            variant="contained"
            sx={{mt: 3, mb: 2}}
            onClick={handleSend}
            disabled={loading}
        >
            Lisa
        </Button>

    </Box>
}
