import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, {useEffect} from "react";
import {User} from "../../model/Users";
import {UserService} from "../../services/UserService";


export default function UserSelect({ onSelect }: { onSelect: (user: User|null) => void}) {
    const [usersById, setUsersById] = React.useState<Map<string, User>>(new Map());
    const [activeUser, setActiveUser] = React.useState<User|null>(null);

    const fetchUsers = (): void => {
        UserService.fetchUsers().then(response => {
            const map = new Map(response.users.map(user => [user.userId, user]));
            setUsersById(map);
        }, error => { setUsersById(new Map<string, User>())});
    }

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value == '') {
            setActiveUser(null);
            onSelect(null);
        } else {
            const user = usersById.get(value);
            if (user) {
                setActiveUser(user);
                onSelect(user);
            }
        }
    };


    useEffect(() => {
        fetchUsers();
    }, []);

    return <div>
        <FormControl sx={{width: 250}}>
            <InputLabel id="user-select-label" size={"small"}>Autor</InputLabel>
            <Select
                variant={'filled'}
                size="small"
                labelId="user-select-label"
                id="user-select"
                value={activeUser ? activeUser.userId : ''}
                onChange={handleChange}
                autoWidth
                label="Autor"
            >
                <MenuItem key="none" value="">
                    <em>None</em>
                </MenuItem>
                {Array.from(usersById.entries()).map(([userId, user]) => (
                    <MenuItem key={userId} value={userId}>{user.fullName}</MenuItem>
                ))}
            </Select>
        </FormControl>
    </div>
}
