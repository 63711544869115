export class ApiError extends Error {
    code: string;
    message: string;
    params: string[];

    constructor(
        code: string,
        message: string,
        params: string[] = []
    ) {
        super();
        this.code = code;
        this.message = message;
        this.params = params;
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

export const API_REQUEST_ERROR: ApiError = new ApiError(
    'WEB_001', 'Ei õnnestu ühendada API teenusega. Kontrollige palun oma internetiühendust'
);
