import * as React from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {UserService} from "../../services/UserService";
import {LinearProgress, Paper} from "@mui/material";
import NoticeForm from "../notices/NoticeForm";
import {useTheme} from "@mui/material/styles";


export default function AddUserPage() {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        UserService.addUser({token: data.get('token') as string}).then(user => navigate("/users")).catch(error => {});

    };

    return (
        <Paper sx={{padding: theme.spacing(4), marginLeft: 'auto', marginRight: 'auto', maxWidth: 900}}>
            <Typography variant="h4" marginBottom={4}>
                Lisa kasutaja
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="token"
                    label="JWT Token"
                    name="token"
                    autoComplete="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    color="secondary"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Lisa
                </Button>
            </Box>

        </Paper>
    );
}
