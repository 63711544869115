import {LogEntry, Notice, NoticeCreateRequest, NoticeSearchRequest} from "../model/Notice";
import {Page} from "../model/Commons";
import {HttpApiService} from "./HttpApiService";

export const NoticeService = {
    fetchNotices: (searchRequest: NoticeSearchRequest): Promise<Page<Notice>> => {
        return HttpApiService.get('/notices/', searchRequest)
            .then(json => Promise.resolve(json as Page<Notice>))
    },
    fetchLog: (searchRequest: NoticeSearchRequest): Promise<Page<LogEntry>> => {
        return HttpApiService.get('/logs/', searchRequest)
            .then(json => Promise.resolve(json as Page<LogEntry>))
    },
    getNotice: (noticeId: number): Promise<Notice> => {
        return fetch(`/api/notices/${noticeId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                    return resp.json()
                } else {
                    return Promise.reject(new Error("failed to load notice"));
                }
            })
            .then(json => Promise.resolve(json as Notice))
    },
    addNotice: (notice: NoticeCreateRequest): Promise<Notice> => {
        return HttpApiService.post('/notices', notice)
            .then(json => Promise.resolve(json as Notice));
    },
    editNotice: (noticeId: string, notice: NoticeCreateRequest): Promise<Notice> => {
        return HttpApiService.put(`/notices/${noticeId}`, notice)
            .then(json => Promise.resolve(json as Notice));
    }
};
