import {API_REQUEST_ERROR, ApiError} from "../model/ApiError";
import {ToastService} from "./ToastService";

const API_URL = '/api';

export const HttpApiService = {
    appendParams(url: string, params: object): string {
        let fullUrl = url;
        let first = true;
        for (const [key, value] of Object.entries(params)) {
            if (value != null) {
                fullUrl += first ? '?' : '&';
                first = false;
                fullUrl += encodeURI(key) + '=' + encodeURI(value);
            }
        }
        return fullUrl
    },
    get(url: string, params: object = {}): Promise<any> {
        let fullUrl = API_URL + HttpApiService.appendParams(url, params);

        const requestInit: RequestInit = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        return HttpApiService.fetchAndHandleError(fullUrl, requestInit);
    },
    post(url: string, body: object): Promise<any> {
        let fullUrl = API_URL + url;
        const requestInit: RequestInit = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };
        return HttpApiService.fetchAndHandleError(fullUrl, requestInit);
    },
    put(url: string, body: object): Promise<any> {
        let fullUrl = API_URL + url;
        const requestInit: RequestInit = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };
        return HttpApiService.fetchAndHandleError(fullUrl, requestInit);
    },
    fetchAndHandleError(fullUrl: string, requestInit: RequestInit): Promise<any> {
        return fetch(fullUrl, requestInit)
            .then(resp => {
                console.log('got response from ' + fullUrl);
                console.log(resp);
                if (resp.status >= 200 && resp.status < 300) {
                    return resp.json().catch( error => {
                        console.log("error catched!")
                        return Promise.reject(null);
                    });
                } else {
                    return resp.json().then(json => {
                        const code = json['code'];
                        const message = json['message'];
                        let apiError: ApiError;
                        if (code && message) {
                            apiError = new ApiError('' + code, '' + message);
                        } else {
                            apiError = API_REQUEST_ERROR;
                        }
                        ToastService.apiError(apiError);
                        return Promise.reject(apiError);
                    }).catch( error => {
                        console.log("error catched!")
                        return Promise.reject(null);
                    });
                }
            }, error => {
                const apiError = error instanceof ApiError ? error : API_REQUEST_ERROR;
                ToastService.apiError(apiError);
                return Promise.reject(apiError);
            });
    }
}
