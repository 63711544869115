import * as React from 'react';
import {useLoaderData, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import 'moment/locale/de';
import {Notice, NoticeCreateRequest} from "../../model/Notice";
import {NoticeService} from "../../services/NoticeService";
import {LinearProgress, Paper} from "@mui/material";
import NoticeForm from "./NoticeForm";
import {ToastService} from "../../services/ToastService";


export default function EditNoticePage() {
    const notice: Notice = useLoaderData() as Notice;
    const theme = useTheme();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSubmit = (request: NoticeCreateRequest) => {
        if (!notice.id) {
            return;
        }
        setLoading(true);
        NoticeService.editNotice('' + notice.id, request).then(notice => {
            ToastService.success("Teade on salvestatud");
            navigate('/notices');
        }, error => { setLoading(false); });
    };

    return (
        <Paper sx={{padding: theme.spacing(4), marginLeft: 'auto', marginRight: 'auto', maxWidth: 900}}>
            <Typography variant="h4" marginBottom={4}>
                Uus Teade
            </Typography>
            {loading && <LinearProgress />}
            <NoticeForm
                loading={loading}
                noticeCreateRequest={notice}
                onNoticeRequestSubmit={handleSubmit}
            />

        </Paper>
    )
        ;
}
