import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useLoaderData, useNavigate} from "react-router-dom";
import {UserList} from "../../model/Users";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddBoxIcon from "@mui/icons-material/AddBox";

export default function UsersPage() {
    const users: UserList = useLoaderData() as UserList;
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <div>
            <TableContainer component={Paper} sx={{marginTop: theme.spacing(2)}}>
                <Box display="flex" flexDirection="row" gap="10px" margin={1} marginBottom={2}>
                    <Typography variant="h4" margin={2}>
                        Kasutajad
                    </Typography>
                    <div>
                        <Button
                            sx={{
                                marginLeft: theme.spacing(1),
                                marginTop: theme.spacing(2)
                            }}
                            color="secondary"
                            variant="contained"
                            startIcon={<AddBoxIcon/>}
                            onClick={() => navigate('/users/add')}
                        >
                            Uus Kasutaja
                        </Button>
                    </div>

                </Box>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Kasutaja nimi</TableCell>
                            <TableCell>Roll</TableCell>
                            <TableCell>Token</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.users.map((user) => (
                            <TableRow
                                key={user.userId}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {user.username}
                                </TableCell>
                                <TableCell>{user.fullName}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell style={{lineBreak: 'anywhere'}}>{user.token}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
