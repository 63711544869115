import {User} from "../../model/Users";
import {HttpApiService} from "../HttpApiService";
import * as React from "react";
import {useEffect} from "react";

export const AuthService = {
    isAuthenticated: false,
    login(token: string) {
        return HttpApiService.post('/auth/login', {token});
    },
    currentUser(): Promise<User | null> {
        return HttpApiService.get('/auth/users/@me')
            .then(response => {
                if (response) {
                    return response as User;
                } else {
                    return null;
                }
            });
    },
    logout() {
        return HttpApiService.post('/auth/logout', {}).then(() => {}, () => {});
    }
}

interface AuthContextType {
    isAuthenticated: null | boolean;
    user: User | null;
    signin: (user: string, success: VoidFunction, failure: VoidFunction) => void;
    signout: (success: VoidFunction, failure: VoidFunction) => void;
    isAdmin: () => boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({children}: { children: React.ReactNode }) {
    let [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
    let [user, setUser] = React.useState<User | null>(null);
    useEffect(() => {
        async function fetchData() {
            return AuthService.currentUser().then(
                user => {
                    if (user) {
                        setUser(user);
                        setIsAuthenticated(true);
                    } else {
                        setUser(null);
                        setIsAuthenticated(false);
                    }
                }, error => {
                    setUser(null);
                    setIsAuthenticated(false);
                }
            );
        }

        if (isAuthenticated === null) {
            fetchData()
        }
    }, []);

    let signin = (user: string, success: VoidFunction, failure: VoidFunction) => {
        return AuthService.login(user).then(response => {
            setUser(response);
            setIsAuthenticated(true);
            console.log('success signing');
            success();
        }, error => {
            setUser(null);
            setIsAuthenticated(true);
            failure();
        });
    };

    let signout = (callback: VoidFunction) => {
        return AuthService.logout().then(response => {
            callback();
        });
    };

    let isAdmin = () => {
        return user?.role == 'administraator';
    }

    let value = {isAuthenticated, user, signin, signout, isAdmin};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}
