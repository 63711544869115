import * as React from 'react';
import {useEffect} from 'react';
import SignIn from "./components/SignIn";
import {createBrowserRouter, Navigate, RouterProvider, useLocation, useNavigate} from "react-router-dom";
import Main from "./components/Main";
import UsersPage from "./components/users/UsersPage";
import LogsPage from "./components/logs/LogsPage";
import NoticesPage from "./components/notices/NoticesPage";
import AddNoticePage from "./components/notices/AddNoticePage";
import AuthProvider, {useAuth} from "./services/auth/AuthProvider";
import {UserService} from "./services/UserService";
import AddUserPage from "./components/users/AddUserPage";
import {NoticeService} from "./services/NoticeService";
import EditNoticePage from "./components/notices/EditNoticePage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function RequireAuth({children}: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (auth.isAuthenticated == null) {
        return <div>loading</div>;
    } else if (auth.isAuthenticated) {
        return children;
    } else {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
}

function RedirectToRoute({route}: {route: string}) {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(route);
    }, []);
    return <></>
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <RequireAuth><Main/></RequireAuth>,
        children: [
            {
                path: "/",
                element: <RedirectToRoute route="/notices"/>
            },
            {
                path: "/users",
                loader: UserService.fetchUsers,
                element: <UsersPage/>
            },
            {
                path: "/users/add",
                element: <AddUserPage/>
            },
            {
                path: "/logs",
                element: <LogsPage/>
            },
            {
                path: "/notices",
                element: <NoticesPage/>
            },
            {
                path: "/notices/add",
                element: <AddNoticePage/>
            },
            {
                path: "/notices/:noticeId",
                loader: ({params}) => NoticeService.getNotice(Number(params.noticeId)),
                element: <EditNoticePage/>
            }
        ]
    },
    {
        path: "/login",
        element: <SignIn/>,
    },
]);

export default function App() {
    return (
        <AuthProvider>
            <ToastContainer></ToastContainer>
            <RouterProvider router={router}/>
        </AuthProvider>
    );
}
