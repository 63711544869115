import React from "react";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment";

export function MedreDatePicker(
    {onChange, label}: { onChange: (value: moment.Moment|null) => void, label: string}
) {
    return <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
        <DatePicker
            sx={{width: 250}}
            slotProps={{
                textField: {variant: 'filled', size: 'small'},
                field: {clearable: true}
            }}

            onChange={onChange}
            label={label}
            name="fromDate"
        />
    </LocalizationProvider>
}
