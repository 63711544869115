import {TablePagination} from "@mui/material";
import * as React from "react";
import {PageRequest} from "../../model/Commons";

export function MedrePagination<T extends PageRequest>(
    {total, pageRequest, onPageRequestChange}: {
        total: number,
        pageRequest: T,
        onPageRequestChange: (pageRequest: T) => void
    }) {

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        onPageRequestChange({...pageRequest, pageNumber: page})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onPageRequestChange({...pageRequest, pageSize: Number(event.target.value)})
    };

    return <TablePagination
        labelRowsPerPage={'Ridu lehekülje kohta'}
        rowsPerPageOptions={[10, 20, 100]}
        component="div"
        count={total}
        rowsPerPage={pageRequest.pageSize}
        page={pageRequest.pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
}
