import {Notice} from "../../model/Notice";
import React from "react";
import {useTheme} from "@mui/material/styles";
import * as CSS from "csstype";

export function NoticeDiff({a, b}: {a: Notice|null, b: Notice|null}) {
    const theme = useTheme();

    if (!a) {
        return <></>
    }

    const diffStyle = (fieldName: keyof object): CSS.Properties<string | number> => {
        if (!a || !b) {
            return {};
        }
        const aObj = a as object;
        const bObj = b as object;

        return (aObj[fieldName] != bObj[fieldName]) ? {background: '#F5ECBD' } : {};
    }

    return <>
        <span style={diffStyle('datetime' as keyof object)}>
            <b>datetime:</b> {a.datetime}
        </span>
        <br/>
        <span style={diffStyle('datetime' as keyof object)}>
            <b>address:</b> {a.address}
        </span>
        <br/>
        <span style={diffStyle('personalCode' as keyof object)}>
            <b>personalCode:</b> {a.personalCode}
        </span>
        <br/>
        <span style={diffStyle('license' as keyof object)}>
            <b>license:</b> {a.license ? 'true' : 'false'}
        </span>
        <br/>
        <span style={diffStyle('comment' as keyof object)}>
            <b>comment:</b> {a.comment}
        </span>
    </>
}
