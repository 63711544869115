import {ApiError} from "../model/ApiError";
import {toast} from "react-toastify";
import React from "react";

export const ToastService = {
    apiError(error: ApiError): void {
        toast.error(
            <>
                <b>{error.code}</b>
                <br/>
                {error.message}
            </>,
            {
                autoClose: 10000
            });
    },
    warning(text: string): void {
        toast.warning(text);
    },
    success(text: string): void {
        toast.success(text);
    }
}
